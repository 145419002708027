@media (max-width: 420px) {
  .natds-input-container {
    width: 250px;
    margin: 10px;
    min-width: 250px;
  }

  .natds-input-container-with-button {
    margin: 10px 0;
    padding-left: 50px;
    width: 250px;
    min-width: 250px;
  }
}

@media (min-width: 420px) {
  .natds-input-container {
    width: 300px;
    margin: 10px;
    min-width: 300px;
  }

  .natds-input-container-with-button {
    margin: 10px 0;
    padding-left: 50px;
    width: 300px;
    min-width: 300px;
  }
}

.MuiAccordion-root::before {
  background-color: #00000000 !important;
}
